import { FeaturedTemplates, FeaturedTemplatesWrap } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import FeaturedTemplateModule, { FeaturedTemplateDataProps } from '../FeaturedTemplateModule';
import { marginContainer } from './FeaturedTemplatesModule.module.css';

export default function FeaturedTemplatesModule({
  className,
  data
}) {
  const {
    body,
    hasMarginBottom,
    primaryCtaCopy,
    primaryCtaTrackingClass,
    primaryCtaTrackingClassMobile,
    primaryCtaUrl,
    secondaryCtaCopy,
    secondaryCtaTrackingClass,
    secondaryCtaTrackingClassMobile,
    secondaryCtaUrl,
    tagline,
    templates,
    title
  } = data;

  const classes = classNames(className, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <FeaturedTemplates
      body={body}
      className={classes}
      tagline={tagline}
      title={title}
    >
      <FeaturedTemplatesWrap
        primaryCTACopy={primaryCtaCopy}
        primaryCTATrackingClass={primaryCtaTrackingClass}
        primaryCTATrackingClassMobile={primaryCtaTrackingClassMobile}
        primaryCTAUrl={primaryCtaUrl}
        secondaryCTACopy={secondaryCtaCopy}
        secondaryCTATrackingClass={secondaryCtaTrackingClass}
        secondaryCTATrackingClassMobile={secondaryCtaTrackingClassMobile}
        secondaryCTAUrl={secondaryCtaUrl}
      >
        {templates.map((item) => (
          <FeaturedTemplateModule key={item.id} data={item} />
        ))}
      </FeaturedTemplatesWrap>
    </FeaturedTemplates>
  );
}

export const FeaturedTemplatesDataPropsObject = {
  body                            : PropTypes.string,
  hasMarginBottom                 : PropTypes.bool,
  primaryCtaCopy                  : PropTypes.string,
  primaryCtaTrackingClass         : PropTypes.string,
  primaryCtaTrackingClassMobile   : PropTypes.string,
  primaryCtaUrl                   : PropTypes.string,
  secondaryCtaCopy                : PropTypes.string,
  secondaryCtaTrackingClass       : PropTypes.string,
  secondaryCtaTrackingClassMobile : PropTypes.string,
  secondaryCtaUrl                 : PropTypes.string,
  tagline                         : PropTypes.string,
  templates                       : PropTypes.arrayOf(FeaturedTemplateDataProps).isRequired,
  title                           : PropTypes.string
};

FeaturedTemplatesModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(FeaturedTemplatesDataPropsObject).isRequired
};

FeaturedTemplatesModule.defaultProps = {
  className : null
};

export const FeaturedTemplatesModuleData = graphql`
  fragment FeaturedTemplatesModuleData on ContentfulComponentFeaturedTemplates {
    body {
      body
    }
    hasMarginBottom
    primaryCtaCopy
    primaryCtaTrackingClass
    primaryCtaTrackingClassMobile
    primaryCtaUrl
    secondaryCtaCopy
    secondaryCtaTrackingClass
    secondaryCtaTrackingClassMobile
    secondaryCtaUrl
    tagline
    templates {
      ...FeaturedTemplateModuleData
    }
    title
  }
`;
