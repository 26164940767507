import { FeaturedTemplate } from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

export default function FeaturedTemplateModule({ data }) {
  const {
    body,
    coverImage,
    coverImageAlt,
    ctaCopy,
    ctaUrl,
    ctaTrackingClass,
    ctaTrackingClassMobile,
    mediaRatio,
    title,
    videoPoster,
    videoTrackingClass,
    videoUrl
  } = data;
  return (
    <FeaturedTemplate
      body={body && body.body}
      coverImageAlt={coverImageAlt}
      coverImageSrcFluid={coverImage.fluid}
      ctaCopy={ctaCopy}
      ctaTrackingClass={ctaTrackingClass}
      ctaTrackingClassMobile={ctaTrackingClassMobile}
      ctaUrl={ctaUrl}
      mediaRatio={mediaRatio}
      title={title}
      videoPoster={videoPoster && videoPoster.fluid.srcWebp}
      videoTrackingClass={videoTrackingClass}
      videoUrl={videoUrl}
    />
  );
}

export const FeaturedTemplateDataProps = PropTypes.shape({
  body : PropTypes.shape({
    body : PropTypes.string
  }),
  coverImage : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }).isRequired,
  coverImageAlt          : PropTypes.string,
  ctaCopy                : PropTypes.string,
  ctaTrackingClass       : PropTypes.string,
  ctaTrackingClassMobile : PropTypes.string,
  ctaUrl                 : PropTypes.string,
  id                     : PropTypes.string.isRequired,
  mediaRatio             : PropTypes.string,
  title                  : PropTypes.string.isRequired,
  videoPoster            : PropTypes.shape({
    fluid : PropTypes.shape({
      srcWebp : PropTypes.string
    })
  }),
  videoTrackingClass : PropTypes.string,
  videoUrl           : PropTypes.string.isRequired
});

FeaturedTemplateModule.propTypes = {
  data : FeaturedTemplateDataProps.isRequired
};

export const FeaturedTemplatesModuleData = graphql`
  fragment FeaturedTemplateModuleData on ContentfulComponentFeaturedTemplate {
    body {
      body
    }
    coverImage {
      fluid(maxWidth: 350) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    coverImageAlt
    ctaCopy
    ctaUrl
    ctaTrackingClass
    ctaTrackingClassMobile
    id
    mediaRatio
    title
    videoPoster {
      fluid(maxWidth: 720) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    videoTrackingClass
    videoUrl
  }
`;
